import { useContext, useLayoutEffect, useState, useMemo } from 'react';
import CookiesContext from './CookiesContext';
import { isInBrowser } from './utils';
export default function useCookies(dependencies) {
    const cookies = useContext(CookiesContext);
    if (!cookies) {
        throw new Error('Missing <CookiesProvider>');
    }
    const [allCookies, setCookies] = useState(() => cookies.getAll());
    if (isInBrowser()) {
        useLayoutEffect(() => {
            function onChange() {
                const newCookies = cookies.getAll({
                    doNotUpdate: true,
                });
                if (shouldUpdate(dependencies || null, newCookies, allCookies)) {
                    setCookies(newCookies);
                }
            }
            cookies.addChangeListener(onChange);
            return () => {
                cookies.removeChangeListener(onChange);
            };
        }, [cookies, allCookies]);
    }
    const setCookie = useMemo(() => cookies.set.bind(cookies), [cookies]);
    const removeCookie = useMemo(() => cookies.remove.bind(cookies), [cookies]);
    const updateCookies = useMemo(() => cookies.update.bind(cookies), [cookies]);
    return [allCookies, setCookie, removeCookie, updateCookies];
}
function shouldUpdate(dependencies, newCookies, oldCookies) {
    if (!dependencies) {
        return true;
    }
    for (let dependency of dependencies) {
        if (newCookies[dependency] !== oldCookies[dependency]) {
            return true;
        }
    }
    return false;
}
